.react-resizable-handle {
    width: 25px;
    height: 25px;
}

.react-resizable-handle-s {
    bottom: 0;
    margin-bottom: -8px;
    transform: rotate(0deg);
}

.light .react-resizable-handle {
    background-image: url('src/assets/pin-bottom-light.svg');
}

.dark .react-resizable-handle {
    background-image: url('src/assets/pin-bottom-dark.svg');
}
