:root {
    /* Font Assets */
    /*--inter: Inter;*/
    --monospace: monospace;

    /* Colors */
    --brand-primary: var(--brand-300);
    --default-font: var(--neutral-50);
    --subtext-color: var(--neutral-400);
    --neutral-border: var(--neutral-700);
    --black: var(--neutral-900);
    --default-background: rgb(12, 13, 22);
    --brand-900: rgb(15, 23, 42);
    --brand-800: rgb(71, 85, 105);
    --brand-700: rgb(51, 65, 85);
    --brand-600: rgb(71, 85, 105);
    --brand-500: rgb(100, 116, 139);
    --brand-400: rgb(148, 163, 184);
    --brand-300: rgb(203, 213, 225);
    --brand-200: rgb(226, 232, 240);
    --brand-100: rgb(241, 245, 249);
    --brand-50: rgb(248, 250, 252);
    --neutral-900: rgb(15, 23, 42);
    --neutral-800: rgb(71, 85, 105);
    --neutral-700: rgb(51, 65, 85);
    --neutral-600: rgb(71, 85, 105);
    --neutral-500: rgb(100, 116, 139);
    --neutral-400: rgb(148, 163, 184);
    --neutral-300: rgb(203, 213, 225);
    --neutral-200: rgb(226, 232, 240);
    --neutral-100: rgb(241, 245, 249);
    --neutral-50: rgb(248, 250, 252);
    --error-900: rgb(60, 24, 26);
    --error-800: rgb(72, 26, 29);
    --error-700: rgb(84, 27, 31);
    --error-600: rgb(103, 30, 34);
    --error-500: rgb(130, 32, 37);
    --error-400: rgb(170, 36, 41);
    --error-300: rgb(229, 72, 77);
    --error-200: rgb(242, 85, 90);
    --error-100: rgb(255, 99, 105);
    --error-50: rgb(254, 236, 238);
    --warning-900: rgb(44, 33, 0);
    --warning-800: rgb(53, 40, 0);
    --warning-700: rgb(62, 48, 0);
    --warning-600: rgb(73, 60, 0);
    --warning-500: rgb(89, 74, 5);
    --warning-400: rgb(112, 94, 0);
    --warning-300: rgb(245, 217, 10);
    --warning-200: rgb(255, 239, 92);
    --warning-100: rgb(240, 192, 0);
    --warning-50: rgb(255, 250, 209);
    --success-900: rgb(19, 40, 25);
    --success-800: rgb(22, 48, 29);
    --success-700: rgb(25, 57, 33);
    --success-600: rgb(29, 68, 39);
    --success-500: rgb(36, 85, 48);
    --success-400: rgb(47, 110, 59);
    --success-300: rgb(70, 167, 88);
    --success-200: rgb(85, 180, 103);
    --success-100: rgb(99, 193, 116);
    --success-50: rgb(229, 251, 235);

    /* Fonts */
    /*--label: 400 12px/16px var(--inter);*/
    /*--label-bold: 600 12px/16px var(--inter);*/
    /*--body: 400 14px/20px var(--inter);*/
    /*--body-bold: 600 14px/20px var(--inter);*/
    /*--subheader: 500 18px/28px var(--inter);*/
    /*--section-header: 500 24px/32px var(--inter);*/
    /*--header: 500 36px/40px var(--inter);*/
    --monospace-body: 400 14px/20px var(--monospace);

    /* Borders */
    --primary-border-style: 1px solid var(--brand-primary);
    --neutral-border-style: 1px solid var(--neutral-border);

    /* Corners */
    --rounded-default: 4px;
    --circular: 9999px;

    /* Shadows */
    --shadow-default: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    --shadow-overlay: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.1);
}

/*@media (min-width: 640px) {*/
/*    .page-contents {*/
/*        padding-left: calc((100vw + 16px - 640px) / 2);*/
/*        padding-right: calc((100vw + 16px - 640px) / 2);*/
/*    }*/
/*}*/

/*@media (min-width: 768px) {*/
/*    .page-contents {*/
/*        padding-left: calc((100vw + 16px - 768px) / 2);*/
/*        padding-right: calc((100vw + 16px - 768px) / 2);*/
/*    }*/
/*}*/

/*@media (min-width: 1024px) {*/
/*    .page-contents {*/
/*        padding-left: calc((100vw + 16px - 1024px) / 2);*/
/*        padding-right: calc((100vw + 16px - 1024px) / 2);*/
/*    }*/
/*}*/

/*@media (min-width: 1280px) {*/
/*    .page-contents {*/
/*        padding-left: calc((100vw + 16px - 1280px) / 2);*/
/*        padding-right: calc((100vw + 16px - 1280px) / 2);*/
/*    }*/
/*}*/

/*@media (min-width: 1536px) {*/
/*    .page-contents {*/
/*        padding-left: calc((100vw + 16px - 1536px) / 2);*/
/*        padding-right: calc((100vw + 16px - 1536px) / 2);*/
/*    }*/
/*}*/

:root.light {
    /* Font Assets */
    /*--inter: Inter;*/
    --monospace: monospace;

    /* Colors */
    --black: rgb(255, 255, 255);
    --default-background: var(--black);
    --brand-900: rgb(248, 250, 252);
    --brand-800: rgb(241, 245, 249);
    --brand-700: rgb(226, 232, 240);
    --brand-600: rgb(203, 213, 225);
    --brand-500: rgb(148, 163, 184);
    --brand-400: rgb(100, 116, 139);
    --brand-300: rgb(71, 85, 105);
    --brand-200: rgb(51, 65, 85);
    --brand-100: rgb(71, 85, 105);
    --brand-50: rgb(15, 23, 42);
    --neutral-900: rgb(248, 250, 252);
    --neutral-800: rgb(241, 245, 249);
    --neutral-700: rgb(226, 232, 240);
    --neutral-600: rgb(203, 213, 225);
    --neutral-500: rgb(148, 163, 184);
    --neutral-400: rgb(100, 116, 139);
    --neutral-300: rgb(71, 85, 105);
    --neutral-200: rgb(51, 65, 85);
    --neutral-100: rgb(71, 85, 105);
    --neutral-50: rgb(15, 23, 42);
    --error-900: rgb(254, 242, 242);
    --error-800: rgb(254, 226, 226);
    --error-700: rgb(254, 202, 202);
    --error-600: rgb(252, 165, 165);
    --error-500: rgb(248, 113, 113);
    --error-400: rgb(239, 68, 68);
    --error-300: rgb(220, 38, 38);
    --error-200: rgb(185, 28, 28);
    --error-100: rgb(153, 27, 27);
    --error-50: rgb(127, 29, 29);
    --warning-50: rgb(254, 252, 232);
    --warning-100: rgb(254, 249, 195);
    --warning-200: rgb(254, 240, 138);
    --warning-300: rgb(253, 224, 71);
    --warning-400: rgb(250, 204, 21);
    --warning-500: rgb(234, 179, 8);
    --warning-600: rgb(202, 138, 4);
    --warning-700: rgb(161, 98, 7);
    --warning-800: rgb(133, 77, 14);
    --warning-900: rgb(113, 63, 18);
    --success-50: rgb(240, 253, 244);
    --success-100: rgb(220, 252, 231);
    --success-200: rgb(187, 247, 208);
    --success-300: rgb(134, 239, 172);
    --success-400: rgb(74, 222, 128);
    --success-500: rgb(34, 197, 94);
    --success-600: rgb(22, 163, 74);
    --success-700: rgb(21, 128, 61);
    --success-800: rgb(22, 101, 52);
    --success-900: rgb(20, 83, 45);

    /* Fonts */
    /*--label: 400 12px/16px var(--inter);*/
    /*--label-bold: 600 12px/16px var(--inter);*/
    /*--body: 400 14px/20px var(--inter);*/
    /*--body-bold: 600 14px/20px var(--inter);*/
    /*--subheader: 500 18px/28px var(--inter);*/
    /*--section-header: 500 24px/32px var(--inter);*/
    /*--header: 500 36px/40px var(--inter);*/
    --monospace-body: 400 14px/20px var(--monospace);

    /* Borders */
    --primary-border-style: 1px solid var(--brand-primary);
    --neutral-border-style: 1px solid var(--neutral-border);

    /* Corners */
    --rounded-default: 4px;
    --circular: 9999px;

    /* Shadows */
    --shadow-default: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    --shadow-overlay: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.1);
}
